.App {
  text-align: center;
  font-family: "Galindo", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  z-index: 1;
}
.Toastify__toast-container{
  display: flex!important;
  justify-content: center!important;
  font-family: "Galindo", sans-serif!important;
}
.Toastify__toast-icon{
  display: none!important;
}
.Toastify__toast--info{
  padding-top: 0!important;
  padding-bottom: 0!important;
  position: relative!important;
  background-color: #00AAFF!important;
  color: #fff!important;
  width: 176px!important;
  padding-left: 40px!important;
  height: 47px!important;
  border-radius: 15px!important;
  border: none!important;
  box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,.5)!important;
  color: #000!important;
  font-family: "Galindo", sans-serif!important;
  font-size: 12px !important;
  &::before{
    content:'';
    position: absolute;
    left: 8px;
    top: 2px;
    display: block;
    width: 52px;
    height: 52px;
    background-image: url(./assets/img/Completed.webp);
    background-position: center;
    background-size: cover;
  }
}
.Toastify__toast--error{
  padding-top: 0!important;
  padding-bottom: 0!important;
  position: relative!important;
  background-color: #00AAFF!important;
  color: #fff!important;
  width: 176px!important;
  padding-left: 65px!important;
  height: 47px!important;
  border-radius: 15px!important;
  border: none!important;
  box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,.5)!important;
  color: #000!important;
  font-family: "Galindo", sans-serif!important;
  font-size: 12px !important;
  &::before{
    content:'';
    position: absolute;
    left: 8px;
    top: 2px;
    display: block;
    width: 52px;
    height: 52px;
    background-image: url(./assets/img/Cencel.webp);
    background-position: center;
    background-size: cover;
  }
}
.Toastify__close-button{
  display: none!important;
}
.Toastify__toast{
  margin: 0!important;
  height: 46px!important;
  min-height: 55px!important;
}
.Toastify__toast-body > div:last-child {
  white-space: break-spaces !important;
}

.color-popup{
  overflow-y: auto;
  color: #fff;
  font-size: 12px;
  line-height: 17px;  
  p, div{
    margin-bottom: 20px;
  }
  p{
    img{
      position: relative;
      top: 2px;
      margin-right: 4px;
      width: 15px;
    }
  }
}
.title-14, .text-14{
  font-size: 14px;
}
.title-20{
  font-size: 20px;
}
.text-12{
  font-size: 12px;
}
.text-16{
  font-size: 16px;
}
.gray-text{
  color: #ADACB2;
}
.blue-text{
  color: #00AAFF;
}
.gold-text{
  color: #FFD800;
}
.silver-text{
  color: #fff;
}
.bronze-text{
  color: #E6A839;
}
.green-text{
  color: #53FF51;
}
.list_Item{
  text-align: left;
  position: relative;
  padding-left: 14px;
  margin-bottom: 20px;
  &::before{
    content: '';
    position: absolute;
    top: 3px;
    left: 2px;
    width: 6.5px;
    height: 6.5px;
    border-radius: 1px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  p{
    margin: 0;
  }
}
.mb10{
  margin-bottom: 10px!important;
}
.chessImg{
  width: 25px;
}
.uppercase{
  text-transform: uppercase;
}
.accBtn{
  font-size: 20px;
  color: #000;
  background-color: rgba(255,255,255, .3);
  padding: 10px;
}