*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
video{
  background-color: rgba(0,0,0,0);
}
button{
  border: none; 
  outline: none;
  background-color: transparent;
  font-family: "Galindo", sans-serif;
}
.backBtn{
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  width: 36px;
  padding: 0;
  img{
    width: 100%;
  }
}
.btnClaim{
  font-size: 20px;
  padding: 1px 10px;
  border-radius: 100px;
  border: 2px solid #000;
  background: linear-gradient(90deg, rgba(241,188,76,1) 0%, rgba(254,234,124,1) 46%, rgba(230,168,57,1) 100%);
  height: 35px;
  color: #000!important;
}
#preloader{
  width: 100vw;
  height: 100vh;
  background-color: #00AAFF;
  background-position: center;
  background-size: cover;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
#preloader svg{
  width: 310px!important;
}
.preloader_hidden {
  opacity: 0!important;
  transition: all 650ms linear 2s;
  z-index: -1!important;
}
.countDownBox{
  font-size: 20px;
  padding: 1px 10px;
  border-radius: 100px;
  border: 2px solid #000;
  background: linear-gradient(90deg, rgba(241,188,76,1) 0%, rgba(254,234,124,1) 46%, rgba(230,168,57,1) 100%);
  height: 35px;
  color: #000!important;
}